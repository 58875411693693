<template>
  <div class="content-box-padding">
    <div class="sub-title">颗粒物、臭氧VOCs源解析</div>
    <div class="line-text" style="margin-top: 20px">
      技术体系 <br />
      综合利用颗粒物组分、臭氧及前体物强化观测数据；<br />
      建立VOCs、Nox、PM2.5排放清单；<br />
      融合受体模型、排放清单、数值模型等多种污染物源解析方法；<br />
      基于时间、空间、行业等不同维度对大气颗粒物、VOCs污染开展溯源分析。<br />
    </div>
    <img
      src="~assets\image\business\颗粒物、臭氧VOCs源解析.png"
      alt="颗粒物、臭氧VOCs源解析"
      style="margin: 50px auto 0px auto; width: 90%"
    />
  </div>
</template>
